<template>
  <div class="features-container occupation">
    <div class="features-wrap">
      <div class="features-content">
        <div class="ellipse">
          <img
            class="yunTop"
            src="../../../assets/img/features/yunTop.png"
            alt=""
          />
          <img
            class="yunBottom"
            src="../../../assets/img/features/yunBottom.png"
            alt=""
          />
        </div>
        <div class="img-wrap">
          <div
            class="features-character feature-image__lazy"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
            :class="{lingLong: activeBallKey === 4}"
          >
            <!-- <img
              :key="activeBallKey"
              :src="occupationList[activeBallKey]"
              alt=""
            /> -->
            <el-image :src="occupationList[activeBallKey]" lazy>
              <div slot="placeholder" class="image-slot">
                <!-- 加载中<span class="dot">...</span> -->
              </div>
            </el-image>
          </div>
        </div>

        <div class="arrow">
          <div
            class="left"
            @click="nextOccupation(false)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/left.png" alt="" />
          </div>
          <div
            class="right"
            @click="nextOccupation(true)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/right.png" alt="" />
          </div>
        </div>
        <div class="ball-bg"></div>
        <div class="ball-wrap">
          <div
            class="ball ball1"
            :class="{ activeBall: activeBallKey === 0 }"
            @click="changeOccupation(0)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            雪芽
          </div>
          <div
            class="ball ball2"
            :class="{ activeBall: activeBallKey === 1 }"
            @click="changeOccupation(1)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            無名
          </div>
          <div
            class="ball ball3"
            :class="{ activeBall: activeBallKey === 2 }"
            @click="changeOccupation(2)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            殊華
          </div>
          <div
            class="ball ball4"
            :class="{ activeBall: activeBallKey === 3 }"
            @click="changeOccupation(3)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            赤陽
          </div>
          <!-- <div class="ball expectBall">期待する</div> -->
          <div
            class="ball ball5"
            :class="{ activeBall: activeBallKey === 4 }"
            @click="changeOccupation(4)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            玲珑
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./arrow.scss";
import { isMobile } from "@/utils/util";

export default {
  name: "Occupation",
  data() {
    return {
      featuresList: ["職業", "従魔", "元魂"],
      featuresIndex: 0,
      stageList: ["融仙期", "変化期", "凝魄期", "定神期", "開霊期", "通窍期"],
      stageActiveKey: 0,
      scrollHeight: 0,
      scrollHeightList: [],
      soulStageActive: 0,
      isMobile,
      activeBallKey: 0,
      occupationList: [
        require("../../../assets/img/features/o/o1.png"),
        require("../../../assets/img/features/o/o2.png"),
        require("../../../assets/img/features/o/o3.png"),
        require("../../../assets/img/features/o/o4.png"),
        require("../../../assets/img/features/o/o5.png"),
      ],
      changeTimer: null,
    };
  },
  methods: {
    changeOccupation(key) {
      this.activeBallKey = key;
    },
    nextOccupation(value) {
      const { activeBallKey } = this;
      // console.log(value, activeBallKey);
      if (value) {
        if (activeBallKey !== 4) {
          this.activeBallKey += 1;
        } else {
          this.activeBallKey = 0;
        }
      } else {
        if (activeBallKey !== 0) {
          this.activeBallKey -= 1;
        } else {
          this.activeBallKey = 4;
        }
      }
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
    on_bot_enter() {
      clearInterval(this.changeTimer);
    },
  },
  mounted() {
    this.on_bot_leave();
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  // margin: 0 183px 70px 85px;
  .features-wrap {
    position: relative;
    // padding-bottom: 240px;
  }
}

.img-wrap {
  min-height: 900px;
}

.features-character {
  width: 65%;
  margin-left: 11%;
  position: relative;
  z-index: 4;
  animation: gradient 1s linear 1;
  overflow:hidden;
}
.lingLong {
  margin-left: 14%;
}
.features-character img{
  width: 100%;
}

@keyframes gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.features-content {
  position: relative;
  // z-index: 4;
}

.ellipse {
  position: absolute;
  top: 278px;
  left: 400px;
  width: 1191px;
  height: 455px;
  border: 1px solid #1c3f61;
  border-radius: 50%;
  z-index: 0;
  border-top: none;
  transform: scale(0.7);
  .yunTop {
    position: absolute;
    top: 236px;
    left: 0;
    width: 64%;
  }
  .yunBottom {
    position: absolute;
    bottom: -80px;
    left: 0;
  }
}

.ball-bg {
  position: absolute;
  top: 416px;
  left: 384px;
  background-image: url(../../../assets/img/ball-small.png);
  width: 1210px;
  height: 108px;
  background-size: cover;
  z-index: 1;
  transform: scale(0.69);
}

.ball-wrap {
  position: absolute;
  top: 416px;
  left: 384px;
  width: 1210px;
  height: 108px;
  background-size: cover;
  z-index: 6;
  transform: scale(0.7);
  .ball {
    position: absolute;
    // top: 156px;
    // left: 17px;
    width: 128px;
    height: 115px;
    background-image: url(../../../assets/img/ball.png);
    background-size: cover;
    text-align: center;
    color: #1c3f61;
    line-height: 76px;
    font-size: 22px;
    cursor: pointer;
  }
  .activeBall {
    background-image: url(../../../assets/img/activeBall.png);
    color: #b79350;
  }
  .ball1 {
    top: 184px;
    left: 17px;
  }
  .ball2 {
    top: 261px;
    left: 183px;
  }
  .ball3 {
    top: 280px;
    right: 300px;
  }
  .ball4 {
    top: 226px;
    right: 128px;
  }
  .ball5 {
    top: 114px;
    right: -25px;
  }
  .expectBall {
    width: 115px;
    top: 114px;
    right: -25px;
    background-image: url(../../../assets/img/expectBall.png);
  }
}

.stage-wrap {
  position: absolute;
  display: flex;
  width: 200px;
  height: 76px;
  background: url(../../../assets/img/features/jinhua.png) no-repeat right;
  background-size: contain;
  z-index: 9;
  .stage {
    display: none;
    flex: 1;
    color: #fff;
    line-height: 76px;
    text-align: right;
    font-size: 20px;
  }
  .stage-img {
    width: 70px;
  }
  .stage-arrow {
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 13px;
    right: 28px;
    bottom: -27px;
    background: url(../../../assets/img/features/yuyinjiantou.png) no-repeat
      right;
    background-size: contain;
  }
}

.big-round1 div:nth-of-type(2) {
  // top: 610px;
  top: 497px;
  left: -158px;
  .stage-arrow {
    right: 28px;
    bottom: -24px;
  }
}
.big-round1 div:nth-of-type(3) {
  top: 606px;
  left: -158px;
  .stage-arrow {
    right: 8px;
    bottom: -26px;
    transform: rotate(-10deg);
  }
}

.big-round1 div:nth-of-type(4) {
  top: 719px;
  left: -122px;
  .stage-arrow {
    right: -2px;
    bottom: -20px;
    transform: rotate(-21deg);
  }
}

.big-round1 div:nth-of-type(5) {
  top: 818px;
  left: -68px;
  .stage-arrow {
    right: -8px;
    bottom: -10px;
    transform: rotate(-28deg);
  }
}

.big-round1 div:nth-of-type(6) {
  top: 905px;
  left: -6px;
  .stage-arrow {
    right: -25px;
    bottom: 0;
    transform: rotate(-38deg);
  }
}

.big-round1 div:nth-of-type(7) {
  top: 972px;
  left: 85px;
  .stage-arrow {
    display: none;
  }
}
</style>