<template>
  <div class="partner-content">
    <swiper ref="skyNameSwiper" :options="nameSwiperOptions">
      <swiper-slide v-for="(item, key) in list" :key="key">
        <div class="portrait">
          <img class="header" :src="item[0]" :toggleKey="key" />
          <img class="name" :src="item[1]" :toggleKey="key" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="transparent">
      {{ activeBallKey }}
    </div>
  </div>
</template>

<script>
import "./style.scss";
import { isMobile } from "@/utils/util";

export default {
  name: "SkyNameSwiper",
  props: ["imgKey"],
  data() {
    const that = this;
    return {
      activeBallKey: 0,
      list: [
        [
          require("../../img/soul/app/0/0i.png"),
          require("../../img/soul/app/0/0n.png"),
        ],
        [
          require("../../img/soul/app/0/1i.png"),
          require("../../img/soul/app/0/1n.png"),
        ],
        [
          require("../../img/soul/app/0/2i.png"),
          require("../../img/soul/app/0/2n.png"),
        ],
        // [
        //   require("../../img/soul/app/0/3i.png"),
        //   require("../../img/soul/app/0/3n.png"),
        // ],
        // [
        //   require("../../img/soul/app/0/4i.png"),
        //   require("../../img/soul/app/0/4n.png"),
        // ],
        // [
        //   require("../../img/soul/app/0/5i.png"),
        //   require("../../img/soul/app/0/5n.png"),
        // ],
        // [
        //   require("../../img/soul/app/0/6i.png"),
        //   require("../../img/soul/app/0/6n.png"),
        // ],
        // [
        //   require("../../img/soul/app/0/7i.png"),
        //   require("../../img/soul/app/0/7n.png"),
        // ],
        [require("../../img/soul/app/0/14i.png"),require("../../img/soul/app/0/14n.png")],
        // [require("../../img/soul/app/0/15.png")],
        [require("../../img/soul/app/0/16i.png"),require("../../img/soul/app/0/16n.png")],
        // [require("../../img/soul/app/0/17.png")],
        // [require("../../img/soul/app/0/18.png")],
        [require("../../img/soul/app/0/19i.png"),require("../../img/soul/app/0/19n.png")],
        [require("../../img/soul/app/0/20i.png"),require("../../img/soul/app/0/20n.png")],
        // [require("../../img/soul/app/0/21.png")],
        [require("../../img/soul/app/0/22i.png"),require("../../img/soul/app/0/22n.png")],
        [require("../../img/soul/app/0/23i.png"),require("../../img/soul/app/0/23n.png")],
        [require("../../img/soul/app/0/24i.png"),require("../../img/soul/app/0/24n.png")], 
        [require("../../img/soul/app/0/25i.png"),require("../../img/soul/app/0/25n.png")],
        [require("../../img/soul/app/0/26i.png"),require("../../img/soul/app/0/26n.png")],
      ],
      nameSwiperOptions: {
        slidesPerView: isMobile ? 6 : 11,
        centeredSlides: true,
        loop: true,
        touchRatio: 0,
        spaceBetween: 35,
        on: {
          touchStart: function (swiper) {
            try {
              const index = swiper.target.attributes.toggleKey.value;
              // console.log(index);
              let activeBallKey = parseInt(index);
              that.setactiveBallKey(activeBallKey);
              that.$emit("emitBallKey", activeBallKey);
            } catch (error) {
              console.log("底部轮播切换出现错误");
            }
          },
        },
      },
    };
  },
  watch: {
    imgKey(value) {
      this.setactiveBallKey(value);
    },
  },
  computed: {
    nameSwiperDom() {
      return this.$refs.skyNameSwiper.swiper;
    },
  },
  methods: {
    setactiveBallKey(activeBallKey) {
      if (isMobile) {
        this.nameSwiperDom.activeIndex = activeBallKey + 6;
      } else {
        this.nameSwiperDom.activeIndex = activeBallKey + 11;
      }
      this.activeBallKey = activeBallKey;
    },
  },
};
</script>
