<template>
  <footer class="page-footer">
    <!-- <img src="../assets/img/endimg.png" alt="" /> -->
    <div class="box_footer">
      <img src="../assets/img/bottomLogo.png" alt="" />
      <div class="text">
        <p>タイトル：山海～神話の世界を覗く異界万華鏡～</p>
        <p>カテゴリ：MMORPG</p>
        <p>対象OS：IOS/Android</p>
        <p>配信時期（配信予定）：2022年</p>
        <p>プレイ料金：無料（一部アイテム課金）</p>
        <p onclick="location.href='mailto:sengai@restargames.com'">
          <span>連絡先：</span>
          <a href="javascript:;">sengai@restargames.jp</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@media (min-width: 751px) {
  .box_footer {
    width: 100%;
    text-align: center;
    padding-bottom: 130px;
    padding-top: 20px;
    & > img {
      width: 10vw;
      // height: 5.7vw;
      margin: 0 auto;
    }
    .text {
      margin-top: 18px;
      p {
        font-size: 27px;
        line-height: 40px;
        color: #1c3f61;
        font-weight: 300;
        font-family: Meiryo UI;
      }
    }
    .email {
      text-align: left;
      display: inline-block;
    }
    a {
      color: #1c3f61;
    }
  }
}
@media (max-width: 750px) {
  .box_footer {
    width: 100%;
    text-align: center;
    padding-bottom: 1.3rem;
    margin-top: 1.3rem;
    & > img {
      width: 2rem;
      margin: 0 auto;
    }
    .text {
      margin-top: 0.3rem;
      p {
        font-size: 0.3rem;
        line-height: 1.5;
        color: #1c3f61;
        font-weight: 300;
        font-family: Meiryo UI;
      }
    }
    .email {
      text-align: left;
      display: inline-block;
    }
    a {
      color: #1c3f61;
    }
  }
}
</style>
