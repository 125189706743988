<template>
  <div class="features-container partner" id="anchor1">
    <div class="features-wrap">
      <div class="features-content">
        <div class="yun-wrap">
          <img
            class="yunTop"
            src="../../../assets/img/features/yunTop.png"
            alt=""
          />
          <img
            class="yunBottom"
            src="../../../assets/img/features/yunBottom.png"
            alt=""
          />
        </div>
        <div class="big-round big-round1">
          <div class="middle-round">
            <div class="small-round"></div>
            <!-- <div>{{stageActiveKey}}</div> -->
          </div>
          <div
            class="stage-wrap"
            v-for="(item, key) in imgList[activeBallKey][0]"
            :key="key"
            @click="setStage(key, activeBallKey)"
            :class="{
              activeStage: stageActiveKey === key,
              hiddenStage: item === 1,
            }"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <div v-show="item !== 1">
              <div
                class="stage"
                :class="{
                  stageDefault: stageActiveKey !== key,
                  stageBg: stageActiveKey === key,
                }"
              ></div>
              <span class="stage-arrow"></span>
              <div class="stage-img">
                <!-- :src="imgList[activeBallKey][0][key]" -->
                <img
                  v-show="item !== 1"
                  :class="{ defalutImg: stageActiveKey !== key }"
                  :src="item"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <swiper ref="partnerSwiper" :options="pSwiperOptions">
          <swiper-slide v-for="(item, key) in imgList" :key="key">
            <div
              class="img-wrap"
              @mouseenter="on_bot_enter()"
              @mouseleave="on_bot_leave()"
            >
              <div class="features-character feature-image__lazy">
                <!-- <el-image :src="item[1][stageActiveKey]" lazy> -->
                <div class="transparent">{{ imageActiveKey }}</div>
                <el-image
                  :src="item[1][imageActiveKey]"
                  lazy
                  class="partner-img-wrap"
                >
                  <div slot="placeholder" class="image-slot"></div>
                </el-image>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="arrow">
          <div
            class="left"
            @click="nextOccupation(false)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/left.png" alt="" />
          </div>
          <div
            class="right"
            @click="nextOccupation(true)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="partner-wrap">
        <div
          class="partner-content"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <swiper ref="nameSwiper" :options="nameSwiperOptions">
            <swiper-slide v-for="(item, key) in swiperList" :key="key">
              <div class="portrait">
                <img class="header" :src="item[0]" alt="" :toggleKey="key" />
                <img class="name" :src="item[1]" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./arrow.scss";

export default {
  name: "Partner",
  data() {
    const that = this;
    return {
      stageActiveKey: 0,
      imageActiveKey: 0,
      activeBallKey: 0,
      imgList: [
        [
          [
            require("../img/partner/9/0i.png"),
            require("../img/partner/9/1i.png"),
            require("../img/partner/9/2i.png"),
            require("../img/partner/9/3i.png"),
            require("../img/partner/9/4i.png"),
            require("../img/partner/9/5i.png"),
          ],
          [
            require("../img/partner/9/0.png"),
            require("../img/partner/9/1.png"),
            require("../img/partner/9/2.png"),
            require("../img/partner/9/3.png"),
            require("../img/partner/9/4.png"),
            require("../img/partner/9/5.png"),
          ],
        ],
        [
          [
            1,
            require("../img/partner/10/1i.png"),
            require("../img/partner/10/2i.png"),
            require("../img/partner/10/3i.png"),
            require("../img/partner/10/4i.png"),
            require("../img/partner/10/5i.png"),
          ],
          [
            require("../img/partner/10/1.png"),
            require("../img/partner/10/2.png"),
            require("../img/partner/10/3.png"),
            require("../img/partner/10/4.png"),
            require("../img/partner/10/5.png"),
          ],
        ],
        [
          [
            1,
            require("../img/partner/11/1i.png"),
            require("../img/partner/11/2i.png"),
            require("../img/partner/11/3i.png"),
            require("../img/partner/11/4i.png"),
            require("../img/partner/11/5i.png"),
          ],
          [
            require("../img/partner/11/1.png"),
            require("../img/partner/11/2.png"),
            require("../img/partner/11/3.png"),
            require("../img/partner/11/4.png"),
            require("../img/partner/11/5.png"),
          ],
        ],
        [
          [
            1,
            require("../img/partner/12/1i.png"),
            require("../img/partner/12/2i.png"),
            require("../img/partner/12/3i.png"),
            require("../img/partner/12/4i.png"),
            require("../img/partner/12/5i.png"),
          ],
          [
            require("../img/partner/12/1.png"),
            require("../img/partner/12/2.png"),
            require("../img/partner/12/3.png"),
            require("../img/partner/12/4.png"),
            require("../img/partner/12/5.png"),
          ],
        ],
        [
          [
            require("../img/partner/8/0i.png"),
            require("../img/partner/8/1i.png"),
            require("../img/partner/8/2i.png"),
            require("../img/partner/8/3i.png"),
            require("../img/partner/8/4i.png"),
            require("../img/partner/8/5i.png"),
          ],
          [
            require("../img/partner/8/0.png"),
            require("../img/partner/8/1.png"),
            require("../img/partner/8/2.png"),
            require("../img/partner/8/3.png"),
            require("../img/partner/8/4.png"),
            require("../img/partner/8/5.png"),
          ],
        ],
      ],
      swiperList: [
        [require("../img/partner/9/0i.png"), require("../img/partner/9/n.png")],
        [
          require("../img/partner/10/1i.png"),
          require("../img/partner/10/n.png"),
        ],
        [
          require("../img/partner/11/1i.png"),
          require("../img/partner/11/n.png"),
        ],
        [
          require("../img/partner/12/1i.png"),
          require("../img/partner/12/n.png"),
        ],
        [require("../img/partner/8/0i.png"), require("../img/partner/8/n.png")],
      ],
      partnerBottomList: [{ name: "" }, {}],
      changeTimer: null,
      swiperAutoplay: true,
      nameSwiperOptions: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides: true,
        touchRatio: 0,
        on: {
          touchStart: function (swiper) {
            try {
              const index = swiper.target.attributes.togglekey.value;
              let activeBallKey = parseInt(index);
              that.setactiveBallKey(activeBallKey);
            } catch (error) {
              console.log("底部轮播切换出现错误");
            }
          },
        },
      },
      pSwiperOptions: {
        loop: true,
        preventClicks: false,
        touchRatio: 0,
      },
    };
  },
  computed: {
    pSwiperDom() {
      return this.$refs.partnerSwiper.swiper;
    },
    nameSwiperDom() {
      return this.$refs.nameSwiper.swiper;
    },
  },
  methods: {
    setStage(key) {
      if (this.imgList[this.activeBallKey][0][0] === 1) {
        console.log(1);
        this.imageActiveKey = key - 1;
      } else {
        this.imageActiveKey = key;
      }
      this.stageActiveKey = key;
    },
    nextOccupation(value) {
      let { activeBallKey } = this;
      // console.log("old", activeBallKey);

      const maxValue = this.imgList.length;
      // console.log(maxValue-1);
      if (value) {
        if (activeBallKey < maxValue - 1) {
          activeBallKey += 1;
        } else {
          activeBallKey = 0;
        }
      } else {
        if (activeBallKey > 0) {
          activeBallKey -= 1;
        } else {
          activeBallKey = maxValue - 1;
        }
      }
      // debugger // eslint-disable-line
      this.setactiveBallKey(activeBallKey);
    },
    setactiveBallKey(activeBallKey) {
      this.imageActiveKey = 0;
      if (this.imgList[activeBallKey][0][0] === 1) {
        this.stageActiveKey = 1;
      } else {
        this.stageActiveKey = 0;
      }

      const maxValue = this.imgList.length;
      this.pSwiperDom.activeIndex = activeBallKey + 1;
      const key = activeBallKey < 3 ? maxValue + activeBallKey : activeBallKey;
      this.nameSwiperDom.activeIndex = key;
      this.activeBallKey = activeBallKey;
    },
    clearTimer() {
      clearInterval(this.changeTimer);
    },
    swiperStart() {
      const that = this;
      this.changeTimer = setInterval(() => {
        that.nextOccupation(true);
      }, 3000);
      // console.log('移出');
    },
    stop() {
      //通过调用鼠标移入移除间接实现暂停播放
      this.btnplay = !this.btnplay;
      this.btnplay ? this.on_bot_leave() : this.on_bot_enter();
    },
    on_bot_enter() {
      this.clearTimer();
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
  },
  mounted() {
    this.on_bot_leave();
  },
};
</script>

<style lang="scss" scoped>
.features-wrap {
  position: relative;
}
.features-content {
  position: relative;
}
.ellipse {
  position: absolute;
  top: 378px;
  left: 381px;
  width: 1191px;
  height: 455px;
  border: 1px solid #1c3f61;
  border-radius: 50%;
  z-index: 0;
  border-top: none;
  .yunTop {
    position: absolute;
    top: 236px;
    left: 0;
    width: 64%;
  }
  .yunBottom {
    position: absolute;
    bottom: -80px;
    left: 0;
  }
}

.transparent {
  color: transparent;
  line-height: 0;
}

.img-wrap {
  // padding-top: 345px;
  width: 100%;
  // height: 770px;
  margin: 0 auto;
  position: relative;
  left: -44px;
  img {
    // width: 100%;
    // max-height: 100%;
    height: 770px;
    width: auto;
    margin: 0 auto;
  }
}

.arrow {
  position: absolute;
  top: 500px;
  left: 626px;
}

.ball-bg {
  position: absolute;
  top: 516px;
  left: 364px;
  background-image: url(../../../assets/img/ball-small.png);
  width: 1210px;
  height: 108px;
  background-size: cover;
  z-index: 1;
}

.ball-wrap {
  position: absolute;
  top: 516px;
  left: 364px;
  width: 1210px;
  height: 108px;
  background-size: cover;
  z-index: 4;
  .ball {
    position: absolute;
    // top: 156px;
    // left: 17px;
    width: 128px;
    height: 115px;
    background-image: url(../../../assets/img/ball.png);
    background-size: cover;
    text-align: center;
    color: #1c3f61;
    line-height: 76px;
    font-size: 18px;
  }
  .activeBall {
    background-image: url(../../../assets/img/activeBall.png);
    color: #b79350;
  }
  .ball1 {
    top: 156px;
    left: 17px;
  }
  .ball2 {
    top: 250px;
    left: 183px;
  }
  .ball3 {
    top: 280px;
    right: 300px;
  }
  .ball4 {
    top: 226px;
    right: 128px;
  }
  .expectBall {
    width: 115px;
    top: 114px;
    right: -25px;
    background-image: url(../../../assets/img/expectBall.png);
  }
}

.big-round {
  position: absolute;
  // top: 173px;
  left: 400px;
  width: 1116px;
  height: 1100px;
  background: url(../../../assets/img/features/big-round.png) no-repeat;
  background-size: cover;
  .middle-round {
    position: absolute;
    top: 160px;
    left: 169px;
    width: 813px;
    height: 743px;
    background: url(../../../assets/img/features/middle-round.png) no-repeat;
    background-size: cover;
    transform: rotate(-45deg);
    .small-round {
      position: absolute;
      top: 117px;
      left: 216px;
      width: 467px;
      height: 467px;
      background: url(../../../assets/img/features/small-round.png) no-repeat;
      background-size: cover;
    }
  }
}

.stage-wrap {
  position: absolute;
  // display: flex;
  width: 200px;
  height: 60px;
  // background: url(../../../assets/img/features/jinhua.png) no-repeat right;
  // background-image: url(../../../assets/img/features/jinhua.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  z-index: 9;
  cursor: pointer;
  .stage {
    // display: none;
    flex: 1;
    color: #fff;
    line-height: 76px;
    text-align: center;
    font-size: 20px;
  }
  .stage-img {
    width: 56px;
    height: 57px;
    position: absolute;
    top: 2px;
    right: 2px;
    img {
      height: 100%;
      width: 100%;
    }
    .defalutImg {
      height: 80%;
      width: 80%;
      margin: 5px auto;
    }
  }
  .stage-arrow {
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 13px;
    right: 28px;
    bottom: -27px;
    background: url(../../../assets/img/features/yuyinjiantou.png) no-repeat
      right;
    background-size: contain;
  }
}
.hiddenStage + .stage-wrap {
  .stage-arrow {
    display: none;
  }
}
.activeStage {
  // background: url(../../../assets/img/features/activeStage.png) no-repeat right;
  background-size: contain;
}

.stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.stageDefault {
  background-image: url(../../../assets/img/features/stageDefault.png);
}

.big-round1 > div:nth-of-type(2) {
  top: 200px;
  right: 70px;
  .stageBg {
    background-image: url(../../../assets/img/features/red.png);
  }
  .stage-arrow {
    display: none;
  }
}
.big-round1 div:nth-of-type(3) {
  top: 284px;
  right: 30px;
  .stage-arrow {
    right: 37px;
    top: -16px;
    transform: rotate(-22deg);
  }
  .stageBg {
    background-image: url(../../../assets/img/features/orange.png);
  }
}

.big-round1 div:nth-of-type(4) {
  top: 376px;
  right: -7px;
  .stage-arrow {
    right: 33px;
    top: -21px;
    transform: rotate(-18deg);
  }
  .stageBg {
    background-image: url(../../../assets/img/features/yellow.png);
  }
}

.big-round1 div:nth-of-type(5) {
  top: 471px;
  right: -22px;
  .stage-arrow {
    right: 21px;
    top: -23px;
    transform: rotate(-3deg);
  }
  .stageBg {
    background-image: url(../../../assets/img/features/purple.png);
  }
}

.big-round1 div:nth-of-type(6) {
  top: 568px;
  right: -26px;
  .stage-arrow {
    right: 17px;
    top: -26px;
    transform: rotate(5deg);
  }
  .stageBg {
    background-image: url(../../../assets/img/features/green.png);
  }
}

.big-round1 div:nth-of-type(7) {
  top: 663px;
  right: -6px;
  .stageBg {
    background-image: url(../../../assets/img/features/blue.png);
  }
  .stage-arrow {
    right: 4px;
    top: -23px;
    transform: rotate(18deg);
  }
}

.yun-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .yunTop {
    position: absolute;
    top: 58%;
    left: 452px;
    width: 45%;
  }
  .yunBottom {
    position: absolute;
    top: 82%;
    left: 600px;
    width: 360px;
  }
}

.features-character {
  margin: 0 auto;
  // margin-left: 14%;
  position: relative;
  z-index: 4;
  animation: gradient 1s linear 1;
}
@keyframes gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.partner-wrap {
  // width: 90%;
  // margin: 0 auto;
  // padding-top: 22px;
  margin-top: -100px;
  .partner-content {
    // display: flex;
    // justify-content: space-evenly;
    // align-items: center;
    background: url(../../../assets/img/features/featuresHengxian.png) no-repeat
      center;
    background-size: 110%;
    width: 80vw;
    margin: 0 auto;
    // position: relative;
    // left: 123px;
  }
}
.swiper-slide .portrait {
  transform: scale(0.75);
  cursor: pointer;
}
.swiper-slide-active .portrait {
  transform: scale(1);
}
// .portrait img {
//   margin: 0 auto;
//   height: 116px;
// }
.partner-list {
  width: 65px;
  .portrait {
    height: 65px;
    // background: url(../assets/img/features/portrait.png) no-repeat center;
    background-size: cover;

    img {
      width: 100%;
    }
  }
  .partent-name {
    text-align: center;
    line-height: 28px;
    font-size: 18px;
    color: #1c3f61;
  }
}
.partner-content div:nth-of-type(6) {
  width: 115px;
  .partent-name {
    display: none;
  }
  .portrait {
    height: 118px;
  }
}

.partent-active-name {
  width: 121px;
  margin: 10px auto 0;
  font-size: 26px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  background: url(../../../assets/img/features/partent-active-name.png)
    no-repeat center;
  background-size: cover;
}

.portraitIcon {
  bottom: 0;
  top: auto;
}

.swiper-slide-active {
  animation: gradient 1s linear 1;
}
@keyframes gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>