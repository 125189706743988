<template>
  <div class="features-container partner">
    <div class="features-wrap">
      <div class="features-content">
        <div class="yun-wrap">
          <img
            class="yunTop"
            src="../../../assets/img/features/yunTop.png"
            alt=""
          />
          <img
            class="yunBottom"
            src="../../../assets/img/features/yunBottom.png"
            alt=""
          />
        </div>
        <div class="big-round big-round1"></div>
        <swiper ref="appPartnerSwiper" :options="pSwiperOptions">
          <swiper-slide v-for="(item, key) in imgList" :key="key">
            <div
              class="img-wrap"
              @mouseenter="on_bot_enter()"
              @mouseleave="on_bot_leave()"
            >
              <van-image
                :src="item[0][stageActiveKey]"
                lazy-load
                class="partner-lazy-wrap"
              />
            </div>
          </swiper-slide>
        </swiper>
        <div class="arrow">
          <!-- @click="nextOccupation(false)" -->
          <div
            class="left"
            @click="setStage(0)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/left.png" alt="" />
          </div>
          <!-- @click="nextOccupation(true)" -->
          <div
            class="right"
            @click="setStage(1)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="transparent">{{ activeBallKey }}</div>
      <div class="stage-wrap">
        <img :src="stageList[stageActiveKey]" alt="" />
      </div>
      <div class="partner-wrap">
        <div
          class="partner-content"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <swiper ref="nameSwiper" :options="nameSwiperOptions">
            <swiper-slide v-for="(item, key) in swiperList" :key="key">
              <div class="portrait">
                <img class="header" :src="item[0]" alt="" :toggleKey="key" />
                <img class="name" :src="item[1]" alt="" :toggleKey="key" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./arrow.scss";

export default {
  name: "AppPartner",
  data() {
    const that = this;
    return {
      stageActiveKey: 0,
      activeBallKey: 0,
      stageList: [
        require("../img/partner/app/stage/0.png"),
        require("../img/partner/app/stage/1.png"),
        require("../img/partner/app/stage/2.png"),
        require("../img/partner/app/stage/3.png"),
        require("../img/partner/app/stage/4.png"),
        require("../img/partner/app/stage/5.png"),
      ],
      imgList: [
        [
          // [
          //   require("../img/partner/9/0i.png"),
          //   require("../img/partner/9/1i.png"),
          //   require("../img/partner/9/2i.png"),
          //   require("../img/partner/9/3i.png"),
          //   require("../img/partner/9/4i.png"),
          //   require("../img/partner/9/5i.png"),
          // ],
          [
            require("../img/partner/app/9/0.png"),
            require("../img/partner/app/9/1.png"),
            require("../img/partner/app/9/2.png"),
            require("../img/partner/app/9/3.png"),
            require("../img/partner/app/9/4.png"),
            require("../img/partner/app/9/5.png"),
          ],
        ],
        [
          // [
          //   require("../img/partner/10/1i.png"),
          //   require("../img/partner/10/2i.png"),
          //   require("../img/partner/10/3i.png"),
          //   require("../img/partner/10/4i.png"),
          //   require("../img/partner/10/5i.png"),
          // ],
          [
            require("../img/partner/app/10/1.png"),
            require("../img/partner/app/10/2.png"),
            require("../img/partner/app/10/3.png"),
            require("../img/partner/app/10/4.png"),
            require("../img/partner/app/10/5.png"),
          ],
        ],
        [
          // [
          //   require("../img/partner/11/1i.png"),
          //   require("../img/partner/11/2i.png"),
          //   require("../img/partner/11/3i.png"),
          //   require("../img/partner/11/4i.png"),
          //   require("../img/partner/11/5i.png"),
          // ],
          [
            require("../img/partner/app/11/1.png"),
            require("../img/partner/app/11/2.png"),
            require("../img/partner/app/11/3.png"),
            require("../img/partner/app/11/4.png"),
            require("../img/partner/app/11/5.png"),
          ],
        ],
        [
          // [
          //   1,
          //   require("../img/partner/12/1i.png"),
          //   require("../img/partner/12/2i.png"),
          //   require("../img/partner/12/3i.png"),
          //   require("../img/partner/12/4i.png"),
          //   require("../img/partner/12/5i.png"),
          // ],
          [
            require("../img/partner/app/12/1.png"),
            require("../img/partner/app/12/2.png"),
            require("../img/partner/app/12/3.png"),
            require("../img/partner/app/12/4.png"),
            require("../img/partner/app/12/5.png"),
          ],
        ],
        [
          [
            require("../img/partner/app/8/0.png"),
            require("../img/partner/app/8/1.png"),
            require("../img/partner/app/8/2.png"),
            require("../img/partner/app/8/3.png"),
            require("../img/partner/app/8/4.png"),
            require("../img/partner/app/8/5.png"),
          ],
        ],
      ],
      swiperList: [
        [require("../img/partner/9/0i.png"), require("../img/partner/9/n.png")],
        [
          require("../img/partner/10/1i.png"),
          require("../img/partner/10/n.png"),
        ],
        [
          require("../img/partner/11/1i.png"),
          require("../img/partner/11/n.png"),
        ],
        [
          require("../img/partner/12/1i.png"),
          require("../img/partner/12/n.png"),
        ],
        [require("../img/partner/8/0i.png"), require("../img/partner/8/n.png")],
      ],
      itemLength: 0,
      partnerBottomList: [{ name: "" }, {}],
      changeTimer: null,
      swiperAutoplay: true,
      nameSwiperOptions: {
        loop: true,
        slidesPerView: 6,
        spaceBetween: 30,
        centeredSlides: true,
        touchRatio: 0,
        on: {
          touchStart: function (swiper) {
            try {
              const index = swiper.target.attributes.togglekey.value;
              let activeBallKey = parseInt(index);
              that.setactiveBallKey(activeBallKey);
            } catch (error) {
              console.log("底部轮播切换出现错误", error);
            }
          },
        },
      },
      pSwiperOptions: {
        loop: true,
        preventClicks: false,
        touchRatio: 0,
      },
    };
  },
  computed: {
    pSwiperDom() {
      return this.$refs.appPartnerSwiper.swiper;
    },
    nameSwiperDom() {
      return this.$refs.nameSwiper.swiper;
    },
  },
  methods: {
    setStage(key) {
      console.log(key, this.itemLength);
      const max = this.itemLength || this.imgList[0][0].length;
      const stage = this.stageActiveKey;
      // debugger // eslint-disable-line
      if (key) {
        if (stage === max - 1) {
          this.stageActiveKey = 0;
        } else {
          this.stageActiveKey = stage + 1;
        }
      } else {
        if (stage === 0) {
          this.stageActiveKey = max;
        } else {
          this.stageActiveKey = stage - 1;
        }
      }
    },
    nextOccupation(value) {
      let { activeBallKey } = this;
      // console.log("old", activeBallKey);
      this.stageActiveKey = 0;
      const maxValue = this.imgList.length;
      // console.log(maxValue-1);
      if (value) {
        if (activeBallKey < maxValue - 1) {
          activeBallKey += 1;
        } else {
          activeBallKey = 0;
        }
      } else {
        if (activeBallKey > 0) {
          activeBallKey -= 1;
        } else {
          activeBallKey = maxValue - 1;
        }
      }
      // console.log("new", activeBallKey);
      this.setactiveBallKey(activeBallKey);
    },
    setactiveBallKey(activeBallKey) {
      console.log(this.imgList[activeBallKey][0].length);
      this.itemLength = this.imgList[activeBallKey][0].length;
      this.stageActiveKey = 0;
      const maxValue = this.imgList.length;
      this.pSwiperDom.activeIndex = activeBallKey + 1;
      const key = activeBallKey < 3 ? activeBallKey + maxValue : activeBallKey;
      this.nameSwiperDom.activeIndex = key;
      this.activeBallKey = activeBallKey;
    },
    on_bot_enter() {
      // clearInterval(this.changeTimer);
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
  },
  mounted() {
    this.on_bot_leave();
  },
  beforeDestroy() {
    clearInterval(this.changeTimer);
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  padding: 1rem 0;
  background-image: url(../../../assets/img/app/feature/p/bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}
.features-wrap {
  position: relative;
}
.features-content {
  position: relative;
}
.ellipse {
  position: absolute;
  top: 378px;
  left: 381px;
  width: 1191px;
  height: 455px;
  border: 1px solid #1c3f61;
  border-radius: 50%;
  z-index: 0;
  border-top: none;
  .yunTop {
    position: absolute;
    top: 236px;
    left: 0;
    width: 64%;
  }
  .yunBottom {
    position: absolute;
    bottom: -80px;
    left: 0;
  }
}

.img-wrap {
  padding-top: 0.5rem;
  margin: 0 auto;
  position: relative;
  min-height: 7rem;
  img {
    width: 100vw;
    margin: 0 auto;
  }
}

.yun-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .yunTop {
    position: absolute;
    top: 58%;
    left: 0;
    width: 60%;
  }
  .yunBottom {
    position: absolute;
    top: 75%;
    left: 26%;
    width: 50%;
  }
}

.features-character {
  // width: 61%;
  // margin: 0 auto;
  margin-left: 14%;
  position: relative;
  z-index: 4;
  margin: 0 auto;
}

.stage-wrap {
  img {
    margin: 0 auto;
    width: 50vw;
    // animation: stageToLeftRight 2s infinite;
  }
}
@keyframes stageToLeftRight {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.partner-wrap {
  padding-top: 22px;
}
.swiper-slide .portrait {
  transform: scale(0.75);
}
.swiper-slide-active .portrait {
  transform: scale(1);
}

.portrait {
  width: 1.2rem;
  margin: 0 auto;
  .headder {
    height: 0.6rem;
  }
  .name {
    width: 100%;
  }
}

.partent-active-name {
  width: 121px;
  margin: 10px auto 0;
  font-size: 26px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  background: url(../../../assets/img/features/partent-active-name.png)
    no-repeat center;
  background-size: cover;
}

.portraitIcon {
  bottom: 0;
  top: auto;
}

.transparent {
  color: transparent;
  line-height: 0;
}
</style>