<template>
  <div class="partner-content">
    <swiper ref="peopleNameSwiper" :options="peopleNameSwiperOptions">
      <swiper-slide v-for="(item, key) in list" :key="key">
        <div class="portrait">
          <img class="header" :src="item[0]" :toggleKey="key" />
          <img class="name" :src="item[1]" :toggleKey="key" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="transparent">
      {{activeBallKey}}
    </div>
  </div>
</template>

<script>
import "./style.scss";
import { isMobile } from "@/utils/util";


export default {
  name: "PeopleNameSwiper",
  props: ["imgKey"],
  data() {
    const that = this;
    return {
      activeBallKey: 0,
      list: [
        [
          require("../../img/soul/app/2/0i.png"),
          require("../../img/soul/app/2/0n.png"),
        ],
        [
          require("../../img/soul/app/2/1i.png"),
          require("../../img/soul/app/2/1n.png"),
        ],
        [
          require("../../img/soul/app/2/2i.png"),
          require("../../img/soul/app/2/2n.png"),
        ],
        // [
        //   require("../../img/soul/app/2/3i.png"),
        //   require("../../img/soul/app/2/3n.png"),
        // ],
        // [
        //   require("../../img/soul/app/2/4i.png"),
        //   require("../../img/soul/app/2/4n.png"),
        // ],
        // [
        //   require("../../img/soul/app/2/5i.png"),
        //   require("../../img/soul/app/2/5n.png"),
        // ],
        // [
        //   require("../../img/soul/app/2/6i.png"),
        //   require("../../img/soul/app/2/6n.png"),
        // ],
        [
          require("../../img/soul/app/2/7i.png"),
          require("../../img/soul/app/2/7n.png"),
        ],
        [
          require("../../img/soul/app/2/8i.png"),
          require("../../img/soul/app/2/8n.png"),
        ],        
        // [
        //   require("../../img/soul/app/2/9i.png"),
        //   require("../../img/soul/app/2/9n.png"),
        // ],
        // [
        //   require("../../img/soul/app/2/10i.png"),
        //   require("../../img/soul/app/2/10n.png"),
        // ],
         // [require("../../img/soul/app/2/11.png")],
          // [require("../../img/soul/app/2/12.png")],
          // [require("../../img/soul/app/2/13.png")],
          [require("../../img/soul/app/2/14i.png"),require("../../img/soul/app/2/14n.png")],
          [require("../../img/soul/app/2/15i.png"),require("../../img/soul/app/2/15n.png")],
          [require("../../img/soul/app/2/16i.png"),require("../../img/soul/app/2/16n.png")],
          [require("../../img/soul/app/2/17i.png"),require("../../img/soul/app/2/17n.png")],
          [require("../../img/soul/app/2/18i.png"),require("../../img/soul/app/2/18n.png")],
          [require("../../img/soul/app/2/19i.png"),require("../../img/soul/app/2/19n.png")],
          [require("../../img/soul/app/2/20i.png"),require("../../img/soul/app/2/20n.png")],
          // [require("../../img/soul/app/2/21.png")],
          // [require("../../img/soul/app/2/22.png")],
          [require("../../img/soul/app/2/23i.png"),require("../../img/soul/app/2/23n.png")],
          [require("../../img/soul/app/2/24i.png"),require("../../img/soul/app/2/24n.png")],
          // [require("../../img/soul/app/2/25i.png"),require("../../img/soul/app/2/25n.png")],
          [require("../../img/soul/app/2/26i.png"),require("../../img/soul/app/2/26n.png")],
      ],
      peopleNameSwiperOptions: {
        loop: true,
        // slidesPerView: 'auto',
        // loopedSlides: 7,
        // spaceBetween: 20,
        slidesPerView: isMobile?6:11,
        spaceBetween: 30,
        centeredSlides: true,
        touchRatio: 0,
        on: {
          touchStart: function(swiper){
            try {
              const index = swiper.target.attributes.toggleKey.value;
              console.log(index);
              let activeBallKey = parseInt(index);
              that.setactiveBallKey(activeBallKey);
              that.$emit('emitBallKey',activeBallKey);
              // that.$emit('emitBallKey',1);
            } catch (error) {
              console.log('底部轮播切换出现错误');
            }
          },
        },
      },
    };
  },
  watch: {
    imgKey(value) {
      this.setactiveBallKey(value)
    },
  },
  computed: {
    nameSwiperDom() {
      return this.$refs.peopleNameSwiper.swiper;
    },
  },
  methods:{
    setactiveBallKey(activeBallKey) {

      if(isMobile) {
        this.nameSwiperDom.activeIndex = activeBallKey + 6;
      } else {
        // const maxValue = this.list.length
        // const key = activeBallKey < 5 ? maxValue+activeBallKey : activeBallKey
        // this.nameSwiperDom.activeIndex = key;
        this.nameSwiperDom.activeIndex = activeBallKey + 11;
      }
      
      this.activeBallKey = activeBallKey;
    },
    emitBallKey() {

    }
  }
};
</script>
