<template>
  <div class="partner-content">
    <swiper ref="earthNameSwiper" :options="nameSwiperOptions">
      <swiper-slide v-for="(item, key) in list" :key="key">
        <div class="portrait">
          <img class="header" :src="item[0]" :toggleKey="key" />
          <img class="name" :src="item[1]" :toggleKey="key" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="transparent">
      {{activeBallKey}}
    </div>
  </div>
</template>

<script>
import "./style.scss";
import { isMobile } from "@/utils/util";

export default {
  name: "EarthNameSwiper",
  props: ["imgKey"],
  data() {
    const that = this;
    return {
      activeBallKey: 0,
      list: [
        [
          require("../../img/soul/app/1/0i.png"),
          require("../../img/soul/app/1/0n.png"),
        ],
        [
          require("../../img/soul/app/1/1i.png"),
          require("../../img/soul/app/1/1n.png"),
        ],
        [
          require("../../img/soul/app/1/2i.png"),
          require("../../img/soul/app/1/2n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/3i.png"),
        //   require("../../img/soul/app/1/3n.png"),
        // ],
        // [
        //   require("../../img/soul/app/1/4i.png"),
        //   require("../../img/soul/app/1/4n.png"),
        // ],
        [
          require("../../img/soul/app/1/5i.png"),
          require("../../img/soul/app/1/5n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/6i.png"),
        //   require("../../img/soul/app/1/6n.png"),
        // ],
        // [
        //   require("../../img/soul/app/1/7i.png"),
        //   require("../../img/soul/app/1/7n.png"),
        // ],
        [
          require("../../img/soul/app/1/8i.png"),
          require("../../img/soul/app/1/8n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/9i.png"),
        //   require("../../img/soul/app/1/9n.png"),
        // ],
        // [
        //   require("../../img/soul/app/1/10i.png"),
        //   require("../../img/soul/app/1/10n.png"),
        // ],
        [
          require("../../img/soul/app/1/11i.png"),
          require("../../img/soul/app/1/11n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/12i.png"),
        //   require("../../img/soul/app/1/12n.png"),
        // ],
        [
          require("../../img/soul/app/1/13i.png"),
          require("../../img/soul/app/1/13n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/14i.png"),
        //   require("../../img/soul/app/1/14n.png"),
        // ],
        [
          require("../../img/soul/app/1/15i.png"),
          require("../../img/soul/app/1/15n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/16i.png"),
        //   require("../../img/soul/app/1/16n.png"),
        // ],
        [
          require("../../img/soul/app/1/17i.png"),
          require("../../img/soul/app/1/17n.png"),
        ],
        // [
        //   require("../../img/soul/app/1/18i.png"),
        //   require("../../img/soul/app/1/18n.png"),
        // ],
        // [
        //   require("../../img/soul/app/1/19i.png"),
        //   require("../../img/soul/app/1/19n.png"),
        // ],
        [
          require("../../img/soul/app/1/20i.png"),
          require("../../img/soul/app/1/20n.png"),
        ],
        [
          require("../../img/soul/app/1/21i.png"),
          require("../../img/soul/app/1/21n.png"),
        ],
        [
          require("../../img/soul/app/1/22i.png"),
          require("../../img/soul/app/1/22n.png"),
        ],
        [
          require("../../img/soul/app/1/23i.png"),
          require("../../img/soul/app/1/23n.png"),
        ],
        [
          require("../../img/soul/app/1/24i.png"),
          require("../../img/soul/app/1/24n.png"),
        ],
        [
          require("../../img/soul/app/1/25i.png"),
          require("../../img/soul/app/1/25n.png"),
        ],
        [
          require("../../img/soul/app/1/26i.png"),
          require("../../img/soul/app/1/26n.png"),
        ],
      ],
      nameSwiperOptions: {
        loop: true,
        // slidesPerView: 11,
        // spaceBetween: 30,
        slidesPerView: isMobile?6:11,
        spaceBetween: 30,
        centeredSlides: true,
        touchRatio: 0,
        on: {
          touchStart: function(swiper){
            try {
              const index = swiper.target.attributes.toggleKey.value;
              let activeBallKey = parseInt(index);
              that.setactiveBallKey(activeBallKey);
              that.$emit('emitBallKey',activeBallKey);
            } catch (error) {
              console.log('底部轮播切换出现错误');
            }
          },
        },
      },
    };
  },
  watch: {
    imgKey(value) {
      this.setactiveBallKey(value)
    },
  },
  computed: {
    nameSwiperDom() {
      return this.$refs.earthNameSwiper.swiper;
    },
  },
  methods:{
    setactiveBallKey(activeBallKey) {
      if(isMobile) {
        this.nameSwiperDom.activeIndex = activeBallKey + 6;
      } else {
        // const maxValue = this.list.length
        // const key = activeBallKey < 5 ? maxValue+activeBallKey : activeBallKey
        // this.nameSwiperDom.activeIndex = key;
        this.nameSwiperDom.activeIndex = activeBallKey + 11;
      }
      this.activeBallKey = activeBallKey;
    },
    emitBallKey() {

    }
  }
};
</script>
