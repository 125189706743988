<template>
  <div class="features-container soul" id="anchor2">
    <div class="features-wrap">
      <div class="features-content">
        <div class="ellipse">
          <img
            class="yunTop"
            src="../../../assets/img/features/yunTop.png"
            alt=""
          />
          <img
            class="yunBottom"
            src="../../../assets/img/features/yunBottom.png"
            alt=""
          />
        </div>

        <div
          class="img-wrap"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <van-image :src="list[soulStageActive][imgKey][0]" lazy-load />
        </div>

        <div class="arrow">
          <div
            class="left"
            @click="nextOccupation(false)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/left.png" alt="" />
          </div>
          <div
            class="right"
            @click="nextOccupation(true)"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            <img src="../../../assets/img/right.png" alt="" />
          </div>
        </div>

        <div class="soul-stage">
          <div
            class="tian"
            @click="changeActiveStage(0)"
            :class="{ soulActiveStage: soulStageActive === 0 }"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            天
          </div>
          <div
            class="di"
            @click="changeActiveStage(1)"
            :class="{ soulActiveStage: soulStageActive === 1 }"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            地
          </div>
          <div
            class="ren"
            @click="changeActiveStage(2)"
            :class="{ soulActiveStage: soulStageActive === 2 }"
            @mouseenter="on_bot_enter()"
            @mouseleave="on_bot_leave()"
          >
            人
          </div>
        </div>
      </div>

      <div
        class="partner-wrap"
        @mouseenter="on_bot_enter()"
        @mouseleave="on_bot_leave()"
      >
        <SkyNameSwiper
          v-if="soulStageActive === 0"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
        <EarthNameSwiper
          v-if="soulStageActive === 1"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
        <PeopleNameSwiper
          v-if="soulStageActive === 2"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./arrow.scss";

import EarthNameSwiper from "./soul/EarthNameSwiper.vue";
import SkyNameSwiper from "./soul/SkyNameSwiper.vue";
import PeopleNameSwiper from "./soul/PeopleNameSwiper.vue";

export default {
  name: "AppSoul",
  components: { EarthNameSwiper, SkyNameSwiper, PeopleNameSwiper },
  data() {
    // const that = this;
    return {
      changeTimer: null,
      soulStageActive: 0,
      imgKey: 0,
      imgKey0: 0,
      imgKey1: 0,
      imgKey2: 0,
      list: [
                [
          [require("../img/soul/app/0/0.png")],
          [require("../img/soul/app/0/1.png")],
          [require("../img/soul/app/0/2.png")],
          // [require("../img/soul/app/0/3.png")],
          // [require("../img/soul/app/0/4.png")],
          // [require("../img/soul/app/0/5.png")],
          // [require("../img/soul/app/0/6.png")],
          // [require("../img/soul/app/0/7.png")],
          // [require("../img/soul/app/0/8.png")],
          // [require("../img/soul/app/0/9.png")],
          // [require("../img/soul/app/0/10.png")],
          // [require("../img/soul/app/0/11.png")],
          // [require("../img/soul/app/0/12.png")],
          // [require("../img/soul/app/0/13.png")],
          [require("../img/soul/app/0/14.png")],
          // [require("../img/soul/app/0/15.png")],
          [require("../img/soul/app/0/16.png")],
          // [require("../img/soul/app/0/17.png")],
          // [require("../img/soul/app/0/18.png")],
          [require("../img/soul/app/0/19.png")],
          [require("../img/soul/app/0/20.png")],
          // [require("../img/soul/app/0/21.png")],
          [require("../img/soul/app/0/22.png")],
          [require("../img/soul/app/0/23.png")],
          [require("../img/soul/app/0/24.png")], 
          [require("../img/soul/app/0/25.png")],
          [require("../img/soul/app/0/26.png")],
        ],
        [
          [require("../img/soul/app/1/0.png")],
          [require("../img/soul/app/1/1.png")],
          [require("../img/soul/app/1/2.png")],
          // [require("../img/soul/app/1/3.png")],
          // [require("../img/soul/app/1/4.png")],
          [require("../img/soul/app/1/5.png")],
          // [require("../img/soul/app/1/6.png")],
          // [require("../img/soul/app/1/7.png")],
          [require("../img/soul/app/1/8.png")],
          // [require("../img/soul/app/1/9.png")],
          // [require("../img/soul/app/1/10.png")],
          [require("../img/soul/app/1/11.png")],
          // [require("../img/soul/app/1/12.png")],
          [require("../img/soul/app/1/13.png")],
          // [require("../img/soul/app/1/14.png")],
          [require("../img/soul/app/1/15.png")],
          // [require("../img/soul/app/1/16.png")],
          [require("../img/soul/app/1/17.png")],
          // [require("../img/soul/app/1/18.png")],
          // [require("../img/soul/app/1/19.png")],
          [require("../img/soul/app/1/20.png")],
          [require("../img/soul/app/1/21.png")],
          [require("../img/soul/app/1/22.png")],
          [require("../img/soul/app/1/23.png")],
          [require("../img/soul/app/1/24.png")],
          [require("../img/soul/app/1/25.png")],
          [require("../img/soul/app/1/26.png")],
        ],
        [
          [require("../img/soul/app/2/0.png")],
          [require("../img/soul/app/2/1.png")],
          [require("../img/soul/app/2/2.png")],
          // [require("../img/soul/app/2/3.png")],
          // [require("../img/soul/app/2/4.png")],
          // [require("../img/soul/app/2/5.png")],
          // [require("../img/soul/app/2/6.png")],
          [require("../img/soul/app/2/7.png")],
          [require("../img/soul/app/2/8.png")],
          // [require("../img/soul/app/2/9.png")],
          // [require("../img/soul/app/2/10.png")],
          // [require("../img/soul/app/2/11.png")],
          // [require("../img/soul/app/2/12.png")],
          // [require("../img/soul/app/2/13.png")],
          [require("../img/soul/app/2/14.png")],
          [require("../img/soul/app/2/15.png")],
          [require("../img/soul/app/2/16.png")],
          [require("../img/soul/app/2/17.png")],
          [require("../img/soul/app/2/18.png")],
          [require("../img/soul/app/2/19.png")],
          [require("../img/soul/app/2/20.png")],
          // [require("../img/soul/app/2/21.png")],
          // [require("../img/soul/app/2/22.png")],
          [require("../img/soul/app/2/23.png")],
          [require("../img/soul/app/2/24.png")],
          // [require("../img/soul/app/2/25.png")],
          [require("../img/soul/app/2/26.png")],
        ],
      ],
    };
  },
  computed: {
    pSwiperDom() {
      return this.$refs.partnerSwiper.swiper;
    },
  },
  methods: {
    changeActiveStage(key) {
      this.imgKey = 0;
      this.soulStageActive = key;
    },
    nextOccupation(value) {
      // console.log(value);
      const that = this;
      function changeKey0() {
        let imgKey = that.imgKey0;
        const maxValue = that.list[0].length - 1;
        if (value) {
          if (imgKey >= maxValue) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = maxValue;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey0 = imgKey;
      }
      function changeKey1() {
        let imgKey = that.imgKey1;
        const maxValue = that.list[1].length - 1;
        if (value) {
          if (imgKey >= maxValue) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = maxValue;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey1 = imgKey;
      }
      function changeKey2() {
        let imgKey = that.imgKey2;
        const maxValue = that.list[2].length - 1;
        if (value) {
          if (imgKey >= maxValue) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = maxValue;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey2 = imgKey;
      }

      const soulStageActive = this.soulStageActive;
      switch (soulStageActive) {
        case 0:
          changeKey0();
          break;
        case 1:
          changeKey1();
          break;
        case 2:
          changeKey2();
          break;
        default:
          break;
      }
    },
    setActiveBallKey(value) {
      this.imgKey = value;
    },
    on_bot_enter() {
      // clearInterval(this.changeTimer);
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
  },
  watch: {
    soulStageActive() {
      // this.nameSwiperDom.update();
    },
  },
  mounted() {
    // this.on_bot_leave();
  },
  beforeDestroy() {
    clearInterval(this.changeTimer);
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  // margin: 0 183px 70px 85px;
  .features-wrap {
    position: relative;
    // padding-bottom: 240px;
    .features-content {
      position: relative;
    }
  }
}

.soul {
  padding: 70px 0;
}

.ellipse {
  position: absolute;
  top: 1.9rem;
  left: 1rem;
  width: 5.5rem;
  height: 2rem;
  // border: 1px solid #1c3f61;
  // border-radius: 50%;
  z-index: 0;
  // border-top: none;
  .yunTop {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
  }
  .yunBottom {
    position: absolute;
    top: 0.8rem;
    left: 0;
    width: 60%;
  }
}

.arrow {
  position: absolute;
  top: 50%;
}

.soul-stage {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 4;
  margin-bottom: 0.4rem;
  div {
    height: 1rem;
    width: 1rem;
    font-size: 0.3rem;
    background-image: url(../../../assets/img/features/soul-stage.png);
    background-repeat: no-repeat;
    line-height: 0.6rem;
    text-align: center;
    background-size: contain;
    color: #1c3f61;
    margin-left: 0.2rem;
  }
  .soulActiveStage {
    color: #b79350;
    background-image: url(../../../assets/img/features/soul-active-stage.png);
  }
}

.partent-active-name {
  width: 121px;
  margin: 10px auto 0;
  font-size: 26px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  //background: url(../../../assets/img/features/partent-active-name.png) no-repeat center;
  background-size: cover;
}

.img-wrap {
  // width: 80vw;
  height: 9rem;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  img {
    // width: 100%;
    // max-height: 100%;
    // height: 100%;
    width: 100vw;
    margin: 0 auto;
  }
}

.partner-wrap {
  position: relative;
  z-index: 5;
}

.swiper-slide .portrait {
  transform: scale(0.75);
}
.swiper-slide-active .portrait {
  transform: scale(1);
}
</style>