<template>
  <div class="features-container occupation">
    <div class="features-wrap">
      <div class="features-content">
        <div class="ellipse">
          <img
            class="yunTop"
            src="../../../assets/img/features/yunTop.png"
            alt=""
          />
        </div>
        <div
          class="features-character"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"          
        >
          <van-image :src="occupationList[activeBallKey]" lazy-load />
        </div>
        <!-- <img
          class="features-character"
          :src="occupationList[activeBallKey]"
          alt=""
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
          v-lazy="loadingImg"
        /> -->

        <div
          class="arrow"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <div class="left" @click="nextOccupation(false)"></div>
          <div class="right" @click="nextOccupation(true)"></div>
        </div>
      </div>
      <div
        class="ball-wrap"
        @mouseenter="on_bot_enter()"
        @mouseleave="on_bot_leave()"
      >
        <div
          class="ball"
          v-for="(item, key) in directoryList"
          :key="key"
          :class="{ activeBall: activeBallKey === key }"
          @click="changeOccupation(key)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppDirectory",
  data() {
    return {
      directoryList: ["雪芽", "無名", "殊華", "赤陽", "玲珑"],
      occupationList: [
        require("../img/AppDirectory/o1.png"),
        require("../img/AppDirectory/o2.png"),
        require("../img/AppDirectory/o3.png"),
        require("../img/AppDirectory/o4.png"),
        require("../img/AppDirectory/o5.png"),
      ],
      changeTimer: null,
      activeBallKey: 0,
      loadingImg: require("../../../assets/img/loading.gif"),
    };
  },
  methods: {
    changeOccupation(key) {
      this.activeBallKey = key;
    },
    nextOccupation(value) {
      const { activeBallKey } = this;
      // console.log(value, activeBallKey);
      if (value) {
        if (activeBallKey !== 4) {
          this.activeBallKey += 1;
        } else {
          this.activeBallKey = 0;
        }
      } else {
        if (activeBallKey !== 0) {
          this.activeBallKey -= 1;
        } else {
          this.activeBallKey = 4;
        }
      }
    },
    on_bot_enter() {
      // clearInterval(this.changeTimer);
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
  },
  mounted() {
    this.on_bot_leave();
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  // margin: 242px 183px 70px 85px;
  .features-wrap {
    position: relative;
    padding: 1rem 0 0;
  }
}

.features-content {
  position: relative;
  height: 10rem;
  width: 100%;
  img {
    display: block;
    // height: 100%;
    width: 96vw;
    margin: 0 auto;
    position: relative;
  }
}
.features-character {

}
.ellipse {
  position: absolute;
  top: 1.9rem;
  left: 1rem;
  width: 5.5rem;
  height: 2rem;
  // border: 1px solid #1c3f61;
  border-radius: 50%;
  z-index: 0;
  border-top: none;
  .yunTop {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
  }
  .yunBottom {
    position: absolute;
    top: 0.8rem;
    left: 0;
    width: 60%;
  }
}

.gamePlay {
  position: absolute;
  top: 0.3rem;
  left: 45%;
  width: 1rem;
  height: 1rem;
  img {
    width: 100%;
  }
}

.arrow {
  position: absolute;
  top: 5.6rem;
  left: 1.4rem;
  background-image: url(../../../assets/img/arrow.png);
  width: 5rem;
  height: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  .left,
  .right {
    height: 100%;
    width: 2rem;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
}

.ball-wrap {
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.5rem;
  position: relative;
  z-index: 5;
  // background-image: url(../../../assets/img/ball-small.png);
  // width: 1210px;
  // height: 108px;
  // background-size: cover;
  .ball {
    // position: absolute;
    // top: 156px;
    // left: 17px;
    width: 1.25rem;
    height: 1.2rem;
    background-image: url(../../../assets/img/ball.png);
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    color: #1c3f61;
    line-height: 0.8rem;
    font-size: 0.2rem;
  }
  .activeBall {
    background-image: url(../../../assets/img/activeBall.png);
    color: #b79350;
  }

  .expectBall {
    background-image: url(../../../assets/img/expectBall.png);
  }
}

.features {
  position: fixed;
  top: 5rem;
  right: 0.1rem;
  // height: 2.5rem;
  width: 0.6rem;
  z-index: 3;
  // padding-top: .4rem;
  .features-item {
    font-size: 0.24rem;
    color: #fff;
    text-align: center;
    height: 1.6rem;
    box-sizing: border-box;
    background-size: cover;
    background-image: url(../../../assets/img/features.png);
    background-size: cover;
    span {
      display: inline-block;
      text-align: center;
      width: 0.3rem;
      line-height: 0.33rem;
      padding-top: 0.4rem;
    }
  }
  img {
    margin-left: 0.09rem;
    width: 0.3rem;
  }
}
.features::before {
  content: "";
  display: block;
  height: 0.4rem;
  background-image: url(../../../assets/img/rope.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 0.09rem 0.9rem;
}
.portraitIcon {
  bottom: 0.2rem;
  top: auto;
}
</style>