<template>
  <div class="features-container soul" id="anchor2">
    <div class="features-wrap">
      <div class="features-content">
        <div class="big-round">
          <div class="middle-round">
            <div class="small-round"></div>
          </div>
          <div class="soul-stage">
            <div
              class="tian"
              @click="changeActiveStage(0)"
              :class="{ soulActiveStage: soulStageActive === 0 }"
              @mouseenter="on_bot_enter()"
              @mouseleave="on_bot_leave()"
            >
              天
            </div>
            <div
              class="di"
              @click="changeActiveStage(1)"
              :class="{ soulActiveStage: soulStageActive === 1 }"
              @mouseenter="on_bot_enter()"
              @mouseleave="on_bot_leave()"
            >
              地
            </div>
            <div
              class="ren"
              @click="changeActiveStage(2)"
              :class="{ soulActiveStage: soulStageActive === 2 }"
              @mouseenter="on_bot_enter()"
              @mouseleave="on_bot_leave()"
            >
              人
            </div>
          </div>
        </div>

        <div
          class="img-wrap p20"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <!-- <img
            class="features-character"
            :key="soulStageActive + imgKey"
            :src="list[soulStageActive][imgKey][0]"
            alt=""
          /> -->
          <div class="features-character feature-image__lazy" :key="soulStageActive + imgKey">
            <el-image :src="list[soulStageActive][imgKey][0]" lazy>
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
        </div>

        <div
          class="arrow"
          @mouseenter="on_bot_enter()"
          @mouseleave="on_bot_leave()"
        >
          <div class="left" @click="nextOccupation(false)">
            <img src="../../../assets/img/left.png" alt="" />
          </div>
          <div class="right" @click="nextOccupation(true)">
            <img src="../../../assets/img/right.png" alt="" />
          </div>
        </div>
      </div>

      <div
        class="partner-wrap"
        @mouseenter="on_bot_enter()"
        @mouseleave="on_bot_leave()"
      >
        <SkyNameSwiper
          v-if="soulStageActive === 0"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
        <EarthNameSwiper
          v-if="soulStageActive === 1"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
        <PeopleNameSwiper
          v-if="soulStageActive === 2"
          :imgKey="imgKey"
          @emitBallKey="setActiveBallKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./arrow.scss";

import EarthNameSwiper from "./soul/EarthNameSwiper.vue";
import SkyNameSwiper from "./soul/SkyNameSwiper.vue";
import PeopleNameSwiper from "./soul/PeopleNameSwiper.vue";

export default {
  name: "Soul",
  components: { EarthNameSwiper, SkyNameSwiper, PeopleNameSwiper },
  data() {
    // const that = this;
    return {
      changeTimer: null,
      soulStageActive: 0,
      imgKey: 0,
      imgKey0: 0,
      imgKey1: 0,
      imgKey2: 0,
      list: [
        [
          [require("../img/soul/0/0.png")],
          [require("../img/soul/0/1.png")],
          [require("../img/soul/0/2.png")],
          // [require("../img/soul/0/3.png")],
          // [require("../img/soul/0/4.png")],
          // [require("../img/soul/0/5.png")],
          // [require("../img/soul/0/6.png")],
          // [require("../img/soul/0/7.png")],
          // [require("../img/soul/0/8.png")],
          // [require("../img/soul/0/9.png")],
          // [require("../img/soul/0/10.png")],
          // [require("../img/soul/0/11.png")],
          // [require("../img/soul/0/12.png")],
          // [require("../img/soul/0/13.png")],
          [require("../img/soul/0/14.png")],
          // [require("../img/soul/0/15.png")],
          [require("../img/soul/0/16.png")],
          // [require("../img/soul/0/17.png")],
          // [require("../img/soul/0/18.png")],
          [require("../img/soul/0/19.png")],
          [require("../img/soul/0/20.png")],
          // [require("../img/soul/0/21.png")],
          [require("../img/soul/0/22.png")],
          [require("../img/soul/0/23.png")],
          [require("../img/soul/0/24.png")], 
          [require("../img/soul/0/25.png")],
          [require("../img/soul/0/26.png")],
        ],
        [
          [require("../img/soul/1/0.png")],
          [require("../img/soul/1/1.png")],
          [require("../img/soul/1/2.png")],
          // [require("../img/soul/1/3.png")],
          // [require("../img/soul/1/4.png")],
          [require("../img/soul/1/5.png")],
          // [require("../img/soul/1/6.png")],
          // [require("../img/soul/1/7.png")],
          [require("../img/soul/1/8.png")],
          // [require("../img/soul/1/9.png")],
          // [require("../img/soul/1/10.png")],
          [require("../img/soul/1/11.png")],
          // [require("../img/soul/1/12.png")],
          [require("../img/soul/1/13.png")],
          // [require("../img/soul/1/14.png")],
          [require("../img/soul/1/15.png")],
          // [require("../img/soul/1/16.png")],
          [require("../img/soul/1/17.png")],
          // [require("../img/soul/1/18.png")],
          // [require("../img/soul/1/19.png")],
          [require("../img/soul/1/20.png")],
          [require("../img/soul/1/21.png")],
          [require("../img/soul/1/22.png")],
          [require("../img/soul/1/23.png")],
          [require("../img/soul/1/24.png")],
          [require("../img/soul/1/25.png")],
          [require("../img/soul/1/26.png")],
        ],
        [
          [require("../img/soul/2/0.png")],
          [require("../img/soul/2/1.png")],
          [require("../img/soul/2/2.png")],
          // [require("../img/soul/2/3.png")],
          // [require("../img/soul/2/4.png")],
          // [require("../img/soul/2/5.png")],
          // [require("../img/soul/2/6.png")],
          [require("../img/soul/2/7.png")],
          [require("../img/soul/2/8.png")],
          // [require("../img/soul/2/9.png")],
          // [require("../img/soul/2/10.png")],
          // [require("../img/soul/2/11.png")],
          // [require("../img/soul/2/12.png")],
          // [require("../img/soul/2/13.png")],
          [require("../img/soul/2/14.png")],
          [require("../img/soul/2/15.png")],
          [require("../img/soul/2/16.png")],
          [require("../img/soul/2/17.png")],
          [require("../img/soul/2/18.png")],
          [require("../img/soul/2/19.png")],
          [require("../img/soul/2/20.png")],
          // [require("../img/soul/2/21.png")],
          // [require("../img/soul/2/22.png")],
          [require("../img/soul/2/23.png")],
          [require("../img/soul/2/24.png")],
          // [require("../img/soul/2/25.png")],
          [require("../img/soul/2/26.png")],
        ],
      ],
    };
  },
  computed: {
    pSwiperDom() {
      return this.$refs.partnerSwiper.swiper;
    },
  },
  methods: {
    changeActiveStage(key) {
      this.imgKey = 0;
      this.soulStageActive = key;
    },
    nextOccupation(value) {
      // console.log(value);
      const that = this;
      const list = this.list;

      function changeKey0() {
        let imgKey = that.imgKey0;
        const max = list[0].length - 1;
        if (value) {
          if (imgKey >= max) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = max;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey0 = imgKey;
      }
      function changeKey1() {
        let imgKey = that.imgKey1;
        const max = list[1].length - 1;
        if (value) {
          if (imgKey >= max) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = max;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey1 = imgKey;
      }
      function changeKey2() {
        let imgKey = that.imgKey2;
        const max = list[2].length - 1;
        if (value) {
          if (imgKey >= max) {
            imgKey = 0;
          } else {
            imgKey++;
          }
        } else {
          if (imgKey <= 0) {
            imgKey = max;
          } else {
            imgKey--;
          }
        }
        that.imgKey = imgKey;
        that.imgKey2 = imgKey;
      }

      const soulStageActive = this.soulStageActive;
      switch (soulStageActive) {
        case 0:
          changeKey0();
          break;
        case 1:
          changeKey1();
          break;
        case 2:
          changeKey2();
          break;
        default:
          break;
      }
    },
    setActiveBallKey(value) {
      this.imgKey = value;
    },
    on_bot_enter() {
      clearInterval(this.changeTimer);
    },
    on_bot_leave() {
      // const that = this;
      // this.changeTimer = setInterval(() => {
      //   that.nextOccupation(true);
      // }, 3000);
    },
  },
  watch: {
    soulStageActive() {
      // this.nameSwiperDom.update();
    },
  },
  mounted() {
    this.on_bot_leave();
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  // margin: 0 183px 70px 85px;
  .features-wrap {
    position: relative;
    // padding-bottom: 240px;
    .features-content {
      position: relative;
    }
  }
}

.arrow {
  position: absolute;
  top: 720px;
}

.p20 {
  padding-top: 200px;
  img {
    width: 50%;
  }
}

.soul-stage {
  position: absolute;
  bottom: 300px;
  left: 68px;
  width: 100px;
  height: 300px;
  z-index: 9;
  div {
    position: absolute;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
    background: url(../../../assets/img/features/soul-stage.png) no-repeat
      center;
    background-size: contain;
    line-height: 58px;
    font-size: 26px;
    color: #1c3f61;
    text-align: center;
    cursor: pointer;
  }
  .tian {
    top: -122px;
    right: -26px;
  }
  .di {
    top: 25px;
    right: -6px;
  }
  .ren {
    top: 153px;
    right: -44px;
  }
  .soulActiveStage {
    color: #b79350;
    background: url(../../../assets/img/features/soul-active-stage.png)
      no-repeat center;
    background-size: contain;
  }
}

.big-round {
  position: absolute;
  top: 150px;
  left: 390px;
  width: 1093px;
  height: 1083px;
  background: url(../../../assets/img/features/big-round.png) no-repeat;
  background-size: cover;
  .middle-round {
    position: absolute;
    top: 150px;
    left: 82px;
    width: 895px;
    height: 822px;
    background: url(../../../assets/img/features/middle-round.png) no-repeat;
    background-size: cover;
    transform: rotate(25deg);
    .small-round {
      position: absolute;
      top: 106px;
      left: 185px;
      width: 572px;
      height: 573px;
      background: url(../../../assets/img/features/small-round.png) no-repeat;
      background-size: cover;
    }
  }
}

.partner-wrap {
  // padding-top: 22px;
  .partner-content {
    background: url(../../../assets/img/features/featuresHengxian.png) no-repeat
      center;
    background-size: 110%;
    width: 80vw;
    margin: 0 auto;
  }
}

.partner-content div:nth-of-type(6) {
  width: 115px;
  .partent-name {
    display: none;
  }
  .portrait {
    height: 118px;
  }
}

.partent-active-name {
  width: 121px;
  margin: 10px auto 0;
  font-size: 26px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  //background: url(../../../assets/img/features/partent-active-name.png) no-repeat center;
  background-size: cover;
}

.img-wrap {
  // padding-top: 300px;
  width: 900px;
  height: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  img {
    // width: 100%;
    // max-height: 100%;
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
}

.features-character {
  animation: gradient 1s linear 1;
}
@keyframes gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>